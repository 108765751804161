@import "@mm-ui/components/resources/scss/metro/01_settings/colorsystem";

.mm-form-field__warning {
  input,
  select {
    box-shadow: 0 0 0 px2rem(2px) color(orange);
  }
}

.pointer {
  cursor: pointer;
}
