.fnc-wide-tooltip {
  .tooltip-inner {
    max-width: none;
  }
}

.inventory-unit__info--tooltip {
  .tooltip-inner {
    min-width: 17rem;
  }
}

.inventory-unit__price-breakdown--tooltip {
  .tooltip-inner {
    background-color: #fffbcc;
    color: #001432;
    border: solid 1px #ccd0d6;
    border-radius: 2px;
    max-width: fit-content;
  }

  .tooltip-arrow {
    display: none;
  }
}
