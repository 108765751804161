@import 'node_modules/@mm-ui/components/resources/scss/metro/02_tools/functions';
@import 'node_modules/@mm-ui/components/resources/scss/metro/01_settings/colorsystem';
@import 'node_modules/@mm-ui/components/resources/scss/metro/01_settings/ui';

.fnc-page-title {
  margin: 0;
}

.fnc-page-header-subtitle {
  margin: 0 0 1rem 0;
}

.fnc-page-subtitle {
  margin: 1rem 0;
}

.fnc-page-table-subtitle {
  margin: 1rem 0 0.5rem 0;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: bold;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .datatable-header-cell-template-wrap {
    height: px2rem(19);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.fnc-hint-tile {
  width: 12rem;
  height: 2rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: color-tint(grey, 40);
}

fnc-invoice-conflicts,
fnc-conflicts-list {
  mm-icon-list-item.red {
    color: unset !important;

    fnc-health-check-panel & {
      color: color(red) !important;
    }
  }
}
