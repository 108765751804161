.fnc-view-page-row {
  margin-bottom: 1rem;
  line-height: 0.5rem;

  label {
    color: color-tint(grey, 40);
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0.25rem;
    padding-bottom: 0;
    line-height: 0.75rem;
  }

  span {
    line-height: 1rem;
    font-size: 0.75rem;
  }

  a {
    font-size: px2rem(12);
    line-height: 1rem;
  }

  .secondary-link {
    font-size: px2rem(11);
    line-height: 1rem;
  }

  &__subtitle {
    margin-top: 0;
  }

  &__icon {
    margin-left: px2rem(2);
  }
}
