@use "sass:math";
@import "@swimlane/ngx-datatable/themes/material";
@import "@angular/cdk/overlay-prebuilt.css";
@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import "generic/typography";
@import "generic/base";
@import "generic/ie";
@import "generic/view-page-row";
@import "generic/tables";
@import "generic/tooltips";
@import "generic/scrollbar";
@import "generic/page-layout";
@import "generic/form-fields";
@import "@ng-select/ng-select/themes/default.theme.css";
