@import 'node_modules/@mm-ui/components/resources/scss/metro/01_settings/colorsystem';
@import 'node_modules/@mm-ui/components/resources/scss/metro/01_settings/z-indexes';

.fnc-table {
  $self: &;

  &:not(&--new) {
    position: absolute;
    width: 100%;
  }

  .mm-table__body {
    .mm-table__cell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .mm-sorter {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .datatable-body .datatable-body-row .datatable-body-cell {
    mm-icon {
      line-height: 1;
    }
  }

  &__column-side {
    padding: 0.5rem !important;
    &__sibling {
      &-right {
        padding-left: 0 !important;
      }
      &-left {
        padding-right: 0 !important;
      }
      &-small-right {
        padding-left: .25rem !important;
      }
      &-small-left {
        padding-right: .25rem !important;
      }
    }
  }

  &__row--no-file-uploaded {
    background-color: #fffde5;

    .mm-table__cell {
      background-color: #fffde5;
    }
  }

  &__row--source-disabled {
    background-color: #f2f2f2;

    .mm-table__cell {
      background-color: #f2f2f2;
    }
  }

  &__cell-inline-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__wrapper {
    // width of 1px is needed to fix issue with ngx-datable window resizing
    // https://github.com/swimlane/ngx-datatable/issues/1090
    width: 1px;
    margin: 0 0 1rem 0;
  }

  &--overflow-visible {
    .ngx-datatable {
      &.sms-table,
      &.sms-table.material {
        overflow: visible;
      }
    }
  }

  &--overlay {
    .ngx-datatable {
      &.sms-table,
      &.sms-table.material {
       z-index: zIndex(modal) - 1;

       &.summary-footer {
         z-index: zIndex(modal) - 2;
       }
      }
    }
  }

  &--clickable-rows {
    .datatable-body .datatable-body-row {
      .datatable-body-cell {
        cursor: pointer;

        &:last-child {
          cursor: auto;
        }
      }
    }

    .mm-table__row {
      cursor: pointer;
    }
  }

  &--not-clickable-rows {
    .datatable-body .datatable-body-row .datatable-body-cell {
      cursor: default;
    }
    .ngx-datatable.sms-table .datatable-body .datatable-body-row:hover,
    .ngx-datatable.sms-table.material .datatable-body .datatable-body-row:hover {
      background: unset;
    }

    .ngx-datatable.sms-table.ngx-datatable-zebra .datatable-body .datatable-row-even:hover,
    .ngx-datatable.sms-table.ngx-datatable-zebra.material .datatable-body .datatable-row-even:hover {
      background-color: color-tint(grey, 95);
    }

    .ngx-datatable {
      &.sms-table,
      &.sms-table.material {
        box-shadow: none;
        &:not(.cell-selection) {
          .datatable-body-row {
            &:hover {
              background: unset;
              cursor: default;
              .datatable-row-group {
                background: unset;
              }
            }
          }
        }
      }
    }
  }

  &__comment {
    display: inline;
    position: relative;
    margin: 0;
    font-size: 0.75rem;
    line-height: 1.625;
    color: color-tint(grey, 60);
    font-style: italic;

    span:first-child {
      padding-right: 1rem;
    }

    #{$self} &--editable {
      display: flex;
      align-items: center;

      .datatable-body-cell-label {
        line-height: 1.2;
      }
    }
  }

  &__comment-text {
    display: inline;
    position: relative;
    margin: 0;
    font-size: 0.6rem;
    line-height: 1.625;
    white-space: pre-wrap;
    word-wrap: break-word;

    span:first-child {
      padding-right: 1rem;
    }
  }

  &__comment-icon-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -0.15rem;

    .mm-table & {
      margin-bottom: 0;
    }
  }

  &__comment-icon {
    display: none;
  }

  &__comment--editable:hover {
    cursor: pointer;

    .mm-table__header & {
      cursor: auto;
    }

    span {
      color: color(blue);

      .mm-table__header & {
        color: color(metro-blue);
      }
    }

    #{$self}__comment-icon {
      display: inline;
    }
  }

  &--multiline-header,
  &__multiline-header {
    .datatable-header .datatable-header-inner .datatable-header-cell {
      display: flex;
      align-items: flex-end;

      .datatable-checkbox {
        height: 1rem;
      }

      &.text-right {
        justify-content: flex-end;
      }

      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: flex-end;
        width: auto;
        width: 100%;

        .datatable-header-cell__name {
          max-height: px2rem(39);
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100% - 1rem);
          min-width: 1rem;

          > span:first-child {
            max-height: px2rem(39);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .mm-table__header {
      height: 3.75rem;
      top: -1rem;
      z-index: 1;

      .mm-table__cell {
        display: flex;
        align-items: flex-end;

        > span {
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: px2rem(39);
        }

        &.text-right {
          justify-content: flex-end;
        }

        &.text-center {
          justify-content: center;
        }
      }
    }

    &.mm-table--sticky-progress {
      .mm-progress {
        top: 2.75rem;
      }
    }
  }

  &__multiline-header.ngx-datatable.fixed-header {
    .datatable-header .datatable-header-inner .datatable-header-cell {
      white-space: normal;
    }
  }

  .mm-table__row {
    &:hover {
      .mm-table__cell {
        background-color: color-tint(blue, 95);
      }
    }
  }

  .mm-table__footer {
    justify-content: space-between;
  }

  &.mm-table--sticky-footer {
    .mm-table__footer {
      border-top: 1px solid color-tint(grey, 80);
      bottom: -1rem;
    }
  }
}

.ngx-datatable .datatable-header .datatable-header-cell {
  &.text-right {
    .datatable-header-cell-template-wrap {
      justify-content: flex-end;
    }
  }
  &.text-center {
    .datatable-header-cell-template-wrap {
      justify-content: center;
    }
  }

  &.align-bottom {
    display: flex;
    align-items: flex-end;
    &.text-right {
      justify-content: flex-end;
    }

    &.fnc-table__column-checkbox {
      .datatable-header-cell-template-wrap {
        display: flex;
        padding: 0 0 0.25rem 0;
      }
      &.text-right {
        justify-content: unset;
      }
    }
  }
}
