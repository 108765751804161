@import "node_modules/@mm-ui/components/resources/scss/metro/01_settings/ui";
@import "node_modules/@mm-ui/components/resources/scss/metro/02_tools/functions";
@import "node_modules/@mm-ui/components/resources/scss/metro/01_settings/colorsystem";
@import "node_modules/@mm-ui/components/resources/scss/metro/01_settings/_breakpoints";

body {
  min-width: 1024px;
}

.fnc-page-bar {
  margin-bottom: 1rem;
}

.fnc-page-content {
  padding-top: 0.75rem;
}

.fnc-no-data-box {
  position: relative;
  top: 40vh;
  transform: translateY(-50%);
  height: 10rem;
}

.fnc-btn-small {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.33;

  .m-icon {
    transform: translateY(-0.08rem);
  }
}

.fnc-btn-create {
  margin: 0;
}

.fnc-panel {
  &--is-full-screen {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__content {
    overflow-y: auto;
    padding: 1rem;
    @media (min-width: map-get($breakpoints, "xlarge")) {
      padding: 1rem 1.5rem;
    }
  }

  &__footer--is-sticky {
    width: 100%;
    box-shadow: 0 -0.125rem 0.5rem 0 color-tint(grey, 90);
    background-color: color(white);
    padding: 1rem;
    position: relative;
    z-index: 1;
    @media (min-width: map-get($breakpoints, "xlarge")) {
      padding: 1rem 1.5rem;
    }
  }
}

.fnc-side-grid--is-horizontal-header {
  .fnc-panel--is-full-screen {
    height: calc(100vh - 2rem);
  }
}

.fnc-modal-confirm-window {
  .m-panel-contentPadding {
    margin: 1.5rem;
  }
}

.fnc-document-error {
  margin-bottom: 1rem;
}
