@import "@mm-ui/components/resources/scss/metro/01_settings/_breakpoints";
@import "@mm-ui/components/resources/scss/metro/01_settings/colorsystem";

.m-sidebarNavigation {
  padding-top: 0.5rem;
  margin: 0 0 1.5rem;
}

.fnc-page {
  .fnc-co-table .footer-fixed {
    transition: all ease-in-out 0.2s;
  }

  .m-panel {
    height: 100vh;
    padding: 1rem;
    overflow-y: auto;

    @media (min-width: map-get($breakpoints, "xlarge")) {
      padding: 1rem 1.5rem;
    }

    &--is-side {
      height: 100vh;
      padding: 1rem;
      overflow-y: auto;
      width: 19rem;
      max-width: 19rem;
      flex: unset;
    }

    &--is-main {
      border-right: 1px solid color-tint(grey, 95);
      box-shadow: 0.125rem 0 0.5rem 0 color-tint(grey, 90);
      z-index: 1;
    }

    &--has-inner-panel,
    &--is-main {
      padding: 0;
      overflow: hidden;
    }
  }

  &.fnc-side-grid--is-horizontal-header {
    .m-side-menu,
    .m-panel-main-content,
    .m-panel--is-side {
      margin-top: 2rem;
      height: calc(100vh - 2rem);
    }

    .m-page-sideBar {
      padding-left: 0;
    }

    .m-side-menu--is-closed {
      .fnc-co-table .footer-fixed {
        left: 1.25rem;
        min-width: calc(1024px - 10rem);
      }
    }

    .m-panel-main-content {
      margin-top: 0;
    }

    .m-sideGrid-sideNavigation {
      margin-top: 0.5rem;
    }
    .m-sidebarNavigation {
      padding-top: 0;
    }
  }
}

.m-sideGrid-sideContent {
  display: flex;

  .m-panel--is-side {
    margin: 0;
  }

  &--restricted-width {
    max-width: calc(100% - 10rem);

    @media (min-width: map-get($breakpoints, "xlarge")) {
      max-width: calc(100% - 12.45rem);
    }
  }
}

.m-side-menu {
  height: 100vh;
  overflow-x: hidden;

  .m-sideGrid {
    &-sideNavigation {
      overflow-y: auto;
    }

    &-sideContent--restricted-width {
      max-width: calc(100% - 12.25rem);
    }
  }

  &--is-closed {
    .fnc-co-table .footer-fixed {
      left: 4rem;

      @media (min-width: map-get($breakpoints, "xlarge")) {
        left: 4.25rem;
      }
    }

    .m-sideGrid-sideContent--restricted-width {
      max-width: calc(100% - 0.45rem);
    }
  }
}
